import {
    IAMPORT_CODE,
    BACON_API_DOMAIN,
    CHECKOUT_INIT_URL,
    CHECKOUT_PROCESS_URL,
    ADD_CART_BUY_NOW,
    GET_CART_INFO_URL,
    UPDATE_PAYMENT_METHOD_URL,
    REMOVE_COUPON_FROM_ORDER_URL,
    IAMPORT_KCP_SUBSCRIPTION,
} from '../constants/constants';
import {isMobile} from 'react-device-detect';

export const initCheckout = (
    payData,
    cartData,
    userInfo,
    setIsSpinnerOn,
    customerUid // 해당 필드가 있으면 정기결제로 본다.
) => {
    // 결제 전처리
    IMP.init(IAMPORT_CODE);

    setIsSpinnerOn(true);

    var myOrder = null;
    // var isProcessing = false;
    var inputPG = payData.pg;
    var inputMethod = payData.method;
    var inputWcMethod = payData.wcMethod;
    var inputWcMethodTitle = payData.wcMethodTitle;
    var inputCustomerUid = customerUid;

    // if (isProcessing) return;

    let orderData = {
        payment_method: inputWcMethod,
        payment_method_title: inputWcMethodTitle,
        shipping: {
            first_name: userInfo.receiver,
            last_name: '',
            address_1: userInfo.address_1,
            address_2: userInfo.address_2,
            postcode: userInfo.postcode,
            phone: userInfo.phone,
        },
        customer_note: userInfo.deliveryMsg,
    };

    console.log('orderData : ', orderData);

    // basicPayment(state )

    $.ajax({
        type: 'POST',
        dataType: 'json',
        xhrFields: {
            withCredentials: true,
        },
        contentType: 'application/json',
        url: `${CHECKOUT_INIT_URL}`,
        data: JSON.stringify(orderData),
        success: function (res) {
            // setIsSpinnerShow && setIsSpinnerShow(false);
            // setIsSpinnerOn(false);
            if (res != null) {
                console.log('CHECKOUT_INIT myOrder : ', res);
                myOrder = res.data;

                if (inputCustomerUid != null) {
                    reqSubscriptionPay(myOrder.order_key);
                } else if (myOrder.status === 'order-received') {
                    location.href = `/checkout/order-received/?orderId=${myOrder.id}`;
                } else {
                    reqPay(myOrder.order_key);
                }
            }
        },
        error: function (err) {
            console.log(err);
            // setIsSpinnerShow && setIsSpinnerShow(false);
            setIsSpinnerOn(false);
            // isProcessing = false;

            if (err.responseJSON) {
                alert(err.responseJSON.message);
            } else {
                alert('error');
            }

            location.reload();
        },
    });

    function reqPay() {
        let param;
        if (inputPG == 'naverco') {
            //kakaoPixelForNpay();
            // setIsSpinnerShow && setIsSpinnerShow(false);
            // deleteGlobalSpinner();
            // isProcessing = false;

            console.log('npay process :', myOrder);
            param = {
                pg: inputPG,
                pay_method: inputMethod,
                merchant_uid: myOrder.order_key,
                name: getPayTitleFromCartData(),
                amount: myOrder.total,
                buyer_email: myOrder.billing.email,
                buyer_tel: myOrder.billing.phone,
                m_redirect_url: `${BACON_API_DOMAIN}/api/mobileCheckout/${myOrder.id}`,
                naverProducts: getProductsForNpay(),
                notice_url: `${BACON_API_DOMAIN}/api/npay/notice/${myOrder.id}`,
                //notice_url : "https://wp-collins.gitt.co/order-pay/" + myOrder.id + "/?pay_for_order=true&key=" + myOrder.order_key + "&wc-api=WC_Gateway_Iamport_NaverPay",
                naverInterface: {
                    cpaInflowCode: null,
                    naverInflowCode: null,
                    saClickId: null,
                    merchantCustomCode2: myOrder.order_key,
                },
                naverCultureBenefit: false,
            };

            setIsSpinnerOn(false);
        } else {
            param = {
                pg: inputPG,
                pay_method: inputMethod,
                merchant_uid: myOrder.order_key,
                name: getPayTitleFromCartData(),
                amount: myOrder.total,
                buyer_email: myOrder.billing.email,
                buyer_tel: myOrder.billing.phone,
                m_redirect_url: `${BACON_API_DOMAIN}/api/mobileCheckout/${myOrder.id}`,
            };
        }
        //hideGlobalSpinner();
        console.log({param});
        IMP.request_pay(param, function (rsp) {
            // setIsSpinnerShow && setIsSpinnerShow(true);
            // makeGlobalSpinner();

            //showGlobalSpinner();
            if (rsp.success) {
                console.log(rsp);
                var msg = '결제가 완료되었습니다.';
                msg += '고유ID : ' + rsp.imp_uid;
                msg += '상점 거래ID : ' + rsp.merchant_uid;
                msg += '결제 금액 : ' + rsp.paid_amount;
                msg += '카드 승인번호 : ' + rsp.apply_num;

                checkout(rsp.imp_uid);
                // setIsSpinnerShow && setIsSpinnerShow(false);
            } else {
                var msg = '결제에 실패하였습니다.';
                msg += '\n' + rsp.error_msg;
                // setIsSpinnerShow && setIsSpinnerShow(false);
                setIsSpinnerOn(false);
                alert(msg);

                // isProcessing = false;

                console.log(rsp);

                // removeCouponFromOrder();
            }
        });
    }

    function removeCouponFromOrder() {
        const orderData = {
            id: myOrder.id,
        };

        $.ajax({
            type: 'POST',
            dataType: 'json',
            xhrFields: {
                withCredentials: true,
            },
            contentType: 'application/json',
            url: `${REMOVE_COUPON_FROM_ORDER_URL}`,
            data: JSON.stringify(orderData),
            success: function (data) {
                console.log('coupon removed order : ', data);

                if (data.success) {
                    location.href = `/checkout`;
                }
            },
            error: function (err) {
                console.log(err);
                setIsSpinnerOn(false);
                alert(err.responseJSON.message);

                location.reload();
            },
        });
    }

    function reqSubscriptionPay() {
        let payAmount =
            inputWcMethod == IAMPORT_KCP_SUBSCRIPTION && myOrder.total > 0
                ? 0
                : myOrder.total;
        let merchantUid =
            inputWcMethod == IAMPORT_KCP_SUBSCRIPTION && myOrder.total > 0
                ? myOrder.order_key + '-1'
                : myOrder.order_key;

        console.log('reqSubscriptionPay customer_uid : ', inputCustomerUid);
        let param = {
            pg: inputPG,
            pay_method: inputMethod,
            merchant_uid: merchantUid,
            name: getPayTitleFromCartData(),
            amount: payAmount,
            buyer_email: myOrder.billing.email,
            buyer_tel: myOrder.billing.phone,
            m_redirect_url: `${BACON_API_DOMAIN}/api/mobileCheckout/${myOrder.id}`,
            customer_uid: inputCustomerUid,
        };

        //hideGlobalSpinner();
        console.log('reqSubscriptionPay param', param);
        IMP.request_pay(param, function (rsp) {
            // setIsSpinnerShow && setIsSpinnerShow(true);
            // makeGlobalSpinner();

            //showGlobalSpinner();
            if (rsp.success) {
                console.log(rsp);
                var msg = '결제가 완료되었습니다.';
                msg += '고유ID : ' + rsp.imp_uid;
                msg += '상점 거래ID : ' + rsp.merchant_uid;
                msg += '결제 금액 : ' + rsp.paid_amount;
                msg += '카드 승인번호 : ' + rsp.apply_num;

                checkout(rsp.imp_uid);
                // setIsSpinnerShow && setIsSpinnerShow(false);
            } else {
                var msg = '결제에 실패하였습니다.';
                msg += '\n' + rsp.error_msg;
                // setIsSpinnerShow && setIsSpinnerShow(false);
                setIsSpinnerOn(false);
                alert(msg);

                // isProcessing = false;

                console.log(rsp);
            }
        });
    }

    function checkout(uid) {
        console.log('CHECKOUT_PROCESS, uid : ', uid);
        // setIsSpinnerShow && setIsSpinnerShow(true);
        // makeGlobalSpinner();
        // setOrderNumber(myOrder.id)
        const orderData = {
            id: myOrder.id,
            transaction_id: uid,
            order_key: myOrder.order_key,
        };

        $.ajax({
            type: 'POST',
            dataType: 'json',
            xhrFields: {
                withCredentials: true,
            },
            contentType: 'application/json',
            url: `${CHECKOUT_PROCESS_URL}`,
            data: JSON.stringify(orderData),
            success: function (data) {
                console.log('checkout : ', data);
                //postRedirect( '/checkout/order-received', 'orderId', myOrder.id );
                if (data.success) {
                    location.href = `/checkout/order-received/?orderId=${myOrder.id}`;
                }
            },
            error: function (err) {
                console.log(err);
                setIsSpinnerOn(false);
                alert(err.responseJSON.message);

                location.reload();
            },
        });
    }

    function getPayTitleFromCartData() {
        let title = '';
        if (cartData.cartItems) {
            let cnt = cartData.cartItems.length;

            if (cnt > 1) {
                title =
                    cartData.cartItems[0].product_name +
                    ' 외 ' +
                    (cnt - 1) +
                    '건';
            } else {
                title = cartData.cartItems[0].product_name;
            }
        }

        //console.log('페이 타이틀 ::', title);
        return title;
    }

    function kakaoPixelForNpay() {
        try {
            let itemCnt = 0;

            if (cartData.cartItems) {
                for (let item of cartData.cartItems) {
                    itemCnt = itemCnt + item.quantity;
                }
            }

            let pixelData = {
                total_quantity: itemCnt, // 주문 내 상품 개수(optional)
                total_price: cartData.totals.total_integer, // 주문 총 가격(optional)
                currency: 'KRW', // 주문 가격의 화폐 단위(optional, 기본 값은 KRW)
                products: [],
            };

            console.log('pixelData :: ', pixelData);

            kakaoPixel('1310813083844559940').purchaseNaverCheckout(pixelData);
        } catch (e) {
            console.log('kakaoPixel error :: ', e);
            /* ignore errors from kakaoPixel */
        }
    }

    function getProductsForNpay() {
        if (cartData.cartItems) {
            const products = [];
            for (let item of cartData.cartItems) {
                const lineTotal = item.is_present
                    ? 0
                    : item.fee_applied_line_total
                        ? item.fee_applied_line_total
                        : item.line_total;

                let targetPrice = parseInt(lineTotal / item.quantity);
                const productData = {
                    id: item.product_id,
                    name: item.product_name,
                    basePrice: targetPrice,
                    taxType: 'TAX',
                    infoUrl:
                        window.location.origin + '/store/' + item.product.slug,
                    imageUrl: item.product.thumbnail,
                    // options : [],
                    shipping: {
                        groupId: 'woocommerce',
                        method: 'DELIVERY',
                        baseFee: cartData.totals.shipping_origin_total,
                        feePayType:
                            cartData.totals.shipping_origin_total > 0
                                ? 'PREPAYED'
                                : 'FREE',
                    },
                };

                if (item.variations && item.variations.length > 0) {
                    productData.options = [];
                    for (let variation of item.variations) {
                        let options = [];

                        for (let attr of variation.attributes) {
                            options.push({
                                code: attr.slug,
                                label: attr.name,
                                value: attr.option,
                            });
                        }

                        const productOptionData = {
                            optionQuantity: item.quantity,
                            optionPrice: 0,
                            selectionCode:
                                variation.id +
                                '|' +
                                variation.attributes[0].slug,
                            selections: options,
                            // selections : [
                            //   {
                            //     code : variation.attributes[0].slug,
                            //     label : variation.attributes[0].name,
                            //     value : variation.attributes[0].option
                            //   }
                            // ]
                        };

                        productData.options.push(productOptionData);
                    }
                } else {
                    //  단순일 경우 개수 조정을 위한 장치
                    productData.quantity = item.quantity;
                }
                products.push(productData);
                //itemCnt++;
            }

            console.log('npayProducts::', products);

            return products;
        }

        return [];
    }

    var postRedirect = function (redirectUrl, arg, value) {
        var form = $(
            '<form action="' +
            redirectUrl +
            '" method="post">' +
            '<input type="hidden" name="' +
            arg +
            '" value="' +
            value +
            '"></input>' +
            '</form>'
        );
        $('body').append(form);
        $(form).submit();
    };
};

export function makeNpayButton({
                                   elemIds, // Napy 버튼을 부착할 엘리먼트 ID Arr
                                   buttonKey, // 네이버에게 따로 받은 버튼 key
                                   buyAction, //npay 구매 버튼을 누르면 동작할 함수
                                   getProductsForNpayZzim, // 찜을 누르면 동작할 함수,
                                   withZzim, //찜 활겅화 여부
                               }) {
    // if (C_NPAY != '1') {
    //   return;
    // }

    for (let elemId of elemIds) {
        if (document.getElementById(elemId) == undefined) {
            continue;
        }

        window.naver?.NaverPayButton.apply({
            BUTTON_KEY: buttonKey,
            TYPE: 'MA', //버튼 스타일
            COLOR: 1, //버튼 색상타입
            COUNT: withZzim ? 2 : 1, // 네이버페이버튼 + 찜하기버튼 모두 출력 여부
            ENABLE: 'Y', //네이버페이 활성여부(재고부족 등에는 N으로 비활성처리)
            EMBED_ID: elemId, //네이버페이 버튼 UI가 부착될 HTML element의 ID
            BUY_BUTTON_HANDLER: function () {
                buyAction();
            },
            WISHLIST_BUTTON_HANDLER: function () {
                // 찜액션 호출은 IMP를 통해서 한다.

                if (withZzim) {
                    IMP.naver_zzim({
                        naverProducts: getProductsForNpayZzim(),
                    });
                } else {
                    return;
                }
            },
        });
    }
}

export async function buyNowNpayAction(
    dataToSend, //실제 옵션 state를 서버에서 받을 수 있는 형식으로 변환한 값
    setIsSpinnerOn,
    initData
) {
    if (dataToSend.length === 0) {
        alert('옵션을 선택해야 합니다');
    } else {
        setIsSpinnerOn(true);
        const res = await fetch(ADD_CART_BUY_NOW, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        });

        console.log(dataToSend, 'data before server');
        const data = await res.json();
        if (res.status === 200) {
            const resCart = await fetch(GET_CART_INFO_URL, {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const dataCart = await resCart.json();
            console.log(dataCart, 'data after server');
            if (resCart.status === 200) {
                // initCheckout('naverco', 'card', 'iamport_naverpay', '네이버페이', dataCart);

                const payData = {
                    pg: 'naverco',
                    method: 'card',
                    wcMethod: 'iamport_naverpay',
                    wcMethodTitle: '네이버페이',
                };

                let userInfoForNpay = {
                    firstName: '',
                    receiver: '',
                    phone: '',
                    email: '',
                    postcode: '',
                    address_1: '',
                    address_2: '',
                    deliveryMsg: '',
                };

                if (initData && initData.isLogin) {
                    userInfoForNpay = {
                        firstName: initData.userInfo.first_name,
                        receiver: initData.userInfo.shipping.first_name,
                        phone: initData.userInfo.shipping.phone,
                        email: initData.userInfo.email,
                        postcode: initData.userInfo.shipping.postcode,
                        address_1: initData.userInfo.shipping.address_1,
                        address_2: initData.userInfo.shipping.address_2,
                        deliveryMsg: '',
                    };
                }

                initCheckout(
                    payData,
                    dataCart.data.items,
                    userInfoForNpay,
                    setIsSpinnerOn,
                    null
                );
            }
        } else {
            window.alert(data.message)
            // setIsSpinnerShow(false);
            setIsSpinnerOn(false);
        }
    }
}

export function getProductsForNpayZzim({id, name, slug, uprice, image}) {
    console.log(id, name, slug, uprice, image)
    const textToNumber = Number(uprice.replace(',', '').replace('~', ''))

    const products = [];
    const productZzimData = {
        id: id,
        name: name,
        desc: 'Bacon',
        uprice: textToNumber,
        url: window.location.origin + '/store/' + slug,
        image: image,
    };
    products.push(productZzimData);

    console.log('getProductsForNpayZzim::', products);

    return products;
}

export const reqChangePayMethod = (
    pgData,
    subId,
    merchantUid,
    customerUid,
    setIsSpinnerOn
) => {
    setIsSpinnerOn(true);

    IMP.init(IAMPORT_CODE);

    let param = {
        pg: pgData.pg,
        pay_method: pgData.method,
        merchant_uid: merchantUid,
        amount: 0,
        name: '결제 수단 변경',
        customer_uid: customerUid,
        m_redirect_url: `${BACON_API_DOMAIN}/api/mobileChangeMethod/${pgData.wcMethod}`,
    };

    console.log('reqChangePayMethod : ', param);
    IMP.request_pay(param, function (rsp) {
        //showGlobalSpinner();
        if (rsp.success) {
            console.log(rsp);

            const subscription = {
                id: subId,
                payment_method: pgData.wcMethod,
                payment_method_title: pgData.wcMethodTitle,
            };

            updatePayMethod(subscription, setIsSpinnerOn);
            // setIsSpinnerShow && setIsSpinnerShow(false);
        } else {
            var msg = '빌링키 발급에 실패하였습니다.';
            msg += '\n(' + rsp.error_msg + ')';
            // setIsSpinnerShow && setIsSpinnerShow(false);
            setIsSpinnerOn(false);
            alert(msg);

            console.log(rsp);
        }
    });

    function updatePayMethod(subscription, setIsSpinnerOn) {
        $.ajax({
            type: 'POST',
            dataType: 'json',
            xhrFields: {
                withCredentials: true,
            },
            contentType: 'application/json',
            url: `${UPDATE_PAYMENT_METHOD_URL}`,
            data: JSON.stringify(subscription),
            success: function (data) {
                console.log('checkout : ', data);
                //postRedirect( '/checkout/order-received', 'orderId', myOrder.id );

                alert('결제 수단 변경이 완료되었습니다.');
                //location.reload();
                location.reload();
            },
            error: function (err) {
                console.log(err);
                setIsSpinnerOn(false);
                alert(err.responseJSON.message);

                location.reload();
            },
        });
    }
};
